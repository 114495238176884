<template>
  <div>
    <!--    <van-search-->
    <!--        v-model="Search.Title"-->
    <!--        show-action-->
    <!--        placeholder="请输入标题"-->
    <!--        @search="onSearch"-->
    <!--        @cancel="onCancel"-->
    <!--    />-->
    <van-list>
      <div>
        <div v-if="flag === true">
          <div class="weui-cells" style="margin-top: 10px;" v-for="(item, i) in list" :key="i" >
            <a class="weui-cell weui-cell_access" href="javascript:;" @click="toInfo(item.ID)">
              <div class="weui-media-box__hd">
                <img src="../../../assets/images/Shop.png" alt="" style="width:30px;margin-right:10px;">
              </div>
              <div class="weui-cell__bd">
                <p><span  class="bt">{{item.Dept_Name}}</span><br><span>店铺地址：{{item.Company_Address}}</span></p>
              </div>
              <div class="weui-cell__ft"></div>
            </a>
          </div>
        </div>
        <van-empty description="无数据，请重新查询" v-else/>
      </div>
    </van-list>
  </div>
</template>

<script>
import {mgop} from "@aligov/jssdk-mgop";
import {Toast} from "vant";
import DES from "@/assets/js/DES";
import Emas from "@/assets/js/Emas";

export default {
  name: "shopList",
  data() {
    return {
      list: [],
      type: '',
      value: '',
      flag: true,
      loading: false,   //是否处于加载状态
      finished: false,  //是否已加载完所有数据
      isFinished: false,
      pageCount:'',  //总页数
      token:'',
      Phone:'',
      Info:''
    };
  },
  created() {
    this.token = sessionStorage.getItem("token");
    console.log(this.token)
    this.Phone=sessionStorage.getItem("Mobile");;
    this.fetchData();
    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);
  },
  methods:{
    async fetchData() {
      let strData='{"Phone":"' + this.Phone +'"}';
      // console.log(strData)
      // alert(strData)
      this.Info=DES.encryptDes(strData)
      // console.log(this.Info)
      // let a='{"result":"ok","msg":{"Date":"1","rows":[{"ID":"475","Dept_Name":"宁波市威尔信息科技有限公司","Company_Address":"余姚市凤仪路93号"},{"ID":"129","Dept_Name":"余姚市红曼烟酒店","Company_Address":"浙江省余姚市阳明街道舜水南路4号（自主申报）"}]}}';
      // let data=JSON.parse(a);
      // console.log(a)
      // console.log(data)
      // console.log(data.msg.rows)
      // if (data.result=="ok"){
      //   if (data.msg.rows.length>0){
      //     this.flag=true
      //     this.list=data.msg.rows
      //   }else {
      //     this.flag=false
      //   }
      //
      // }
      // return;



      mgop({
        api: "mgop.will.mqsb.SjYhTs", // 必须
        host: "https://mapi.zjzwfw.gov.cn/",
        dataType: "JSON",
        type: "POST",
        data: {token:this.token,type:'5', Info: this.Info },
        appKey: "3n3b3538+2001833223+ekdhqo", // 必须
        onSuccess: (data) => {
          // alert(JSON.stringify(data))
          console.log("mgop run success login : " + JSON.stringify(data));
          if (data.data.result == "ok") {

            if (data.data.msg.rows.length>0){
              this.list=data.data.msg.rows
              this.flag=true
            }else {
              this.flag=false
            }
          }else if(data.data.result == "401"){
            Toast.fail('token失效，请重新登录');
            this.$router.push({
              path: '/index'
            })
          } else {
            Toast.fail('获取数据失败，请重试');
            this.flag=false
          }
        },
        onFail: (err) => {
          // alert(JSON.stringify(err))
          console.log("mgop run error. error :  " + JSON.stringify(err));
          Toast.fail('获取数据失败，请重试');
          this.flag=false
        },
      });
    },toInfo(ID) {
      if(ID !== "") {
        this.$router.push({
          path: '/shopInfo',
          query: {
            ID: ID,
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>